/* FilterAccordion.css */

.custom-collapse {
  border: none !important;
}
.custom-panel{
  border: none !important;
}
/* Panel başlığının genel görünümünü beyaz yap */
.custom-panel .ant-collapse-header {
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  border: none;
  padding: 0 16px; /* İç boşluk ekleyin */
  position: relative; /* İçerik ve ok simgesini hizalamak için */
  border-bottom: 1px solid #D5D5FA;
}

/* Panel başlığındaki ikonun sağa yerleşmesini sağla */
.custom-panel .ant-collapse-header .ant-collapse-arrow {
  position: absolute; /* İkonun kesin konumunu belirlemek için */
  right: 5px; /* Sağ kenardan uzaklık */
  margin: 0; /* Varsayılan marginleri sıfırla */
  border: none !important;
}

/* Başlık metninin sağında olan alan */

.custom-panel .ant-collapse-header .ant-collapse-header-text {
  flex: 1;
  text-align: left; /* Metni sola hizala */
  margin-left: -25px; /* Sağda ikon için yer bırak */
}
.custom-panel .ant-collapse-content-box {
  padding: 15px !important;
}

.dashed-border {
  border: 1px dashed #E4E4E7; /* Çizgi kalınlığını ve rengini ayarlayın */
  border-width: 1px; /* Çizgi kalınlığını ayarlayın */
  border-style: dashed;
  border-image: repeating-linear-gradient(90deg, transparent, transparent 9px, #E4E4E7 10px, #E4E4E7 20px) 1;
  height: 1px; /* Çizginin kalınlığını ayarlayın */
}